<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col lg="2" cols="6">
        <v-select
          v-model="type"
          :items="types"
          item-text="name"
          item-value="value"
          label="Typ"
          filled
        ></v-select>
      </v-col>
      <v-col lg="2" cols="6">
        <v-select
          v-model="logLevel"
          :items="logLevels"
          item-text="name"
          item-value="value"
          label="Log-Level"
          filled
        ></v-select>
      </v-col>
      <v-col lg="2" cols="6">
        <v-select
          v-model="action"
          :items="actions"
          item-text="name"
          item-value="value"
          label="Action"
          filled
        ></v-select>
      </v-col>

      <v-col lg="2" cols="6">
        <v-text-field
          v-model="tableName"
          placeholder="Tabellename (alle)"
          type="text"
          filled
        ></v-text-field>
      </v-col>

      <v-col lg="2" cols="6">
        <v-text-field
          v-model="companyId"
          placeholder="Company-ID (alle)"
          type="text"
          filled
        ></v-text-field>
      </v-col>

      <v-col lg="2" cols="6">
        <v-text-field
          v-model="userId"
          placeholder="User-ID (alle)"
          type="text"
          filled
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="searchIn"
          :items="searchIns"
          item-text="name"
          item-value="value"
          label="Feld"
          filled
        ></v-select>
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-model="query"
          placeholder="Suchbegriff"
          type="text"
          hint="Suche nach einem bestimmten Wert in der Datenbank. % als Wildcard (%gmx.de%)."
          persistent-hint
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="limit"
          placeholder="Limit"
          type="number"
          hint="Anzahl der Datensätze"
          persistent-hint
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="sortBy"
          :items="sortBys"
          item-text="name"
          item-value="value"
          label="Sortierung nach"
          filled
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="orderBy"
          :items="orderBys"
          item-text="name"
          item-value="value"
          label="Sortierung"
          filled
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-btn @click="getData">Suchen</v-btn>
      </v-col>
    </v-row>

    <v-divider class="ma-6"></v-divider>

    <v-text-field
      v-model="searchProp"
      append-icon="mdi-card-search"
      label="Suchbegriff"
      placeholder="Was suchst Du?"
      solo
      style="max-width: 500px"
    ></v-text-field>

    <v-data-table
      :loading="preloader"
      :headers="headers"
      :items="data"
      :items-per-page="20"
      :search="searchProp"
      class="elevation-1"
    >
      <template v-slot:item.json="{ item }">
        <div
          style="
            max-height: 200px;
            max-width: 400px;
            overflow-y: auto;
            margin: 1rem 0;
            padding: 0.5rem;
            border: 1px solid lightgray;
            border-radius: 5px;
          "
        >
          <pre>{{ JSON.parse(item.json) }}</pre>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
export default {
  name: "LogDebugger",

  components: {
    PageHeader,
  },

  data() {
    return {
      pageTitle: "LogDebugger",
      pageDescription: `<p>Einsehen der System-Logs</p>`,
      title: "",
      preloader: false,
      data: [],
      headers: [],
      componentKey: 0,
      type: "",
      query: "",
      searchProp: "",
      types: [
        { name: "Alle", value: "" },
        { name: "Email", value: "email" },
        { name: "Internal", value: "internal" },
        { name: "Auth", value: "auth" },
        { name: "Notification", value: "notification" },
        { name: "Database", value: "database" },
        { name: "Filesystem", value: "filesystem" },
        { name: "Lead", value: "lead" },
        { name: "API", value: "api" },
      ],
      logLevel: "",
      logLevels: [
        { name: "Alle", value: "" },
        { name: "Info", value: "info" },
        { name: "Warning", value: "warning" },
        { name: "Error", value: "error" },
        { name: "Emergency", value: "emergency" },
      ],
      tableName: "",
      action: "",
      actions: [
        { name: "Alle", value: "" },
        { name: "Create", value: "create" },
        { name: "Update", value: "update" },
        { name: "Delete", value: "delete" },
      ],
      limit: 20,
      offset: 0,
      sortBy: "id",
      orderBy: "DESC",
      sortBys: [
        { name: "ID", value: "id" },
        { name: "Created", value: "created" },
        { name: "Action", value: "action" },
        { name: "Level", value: "level" },
        { name: "Type", value: "type" },
      ],
      orderBys: [
        { name: "Aufsteigend", value: "ASC" },
        { name: "Absteigend", value: "DESC" },
      ],
      searchIn: "data",
      searchIns: [
        { name: "Daten", value: "data" },
        { name: "json", value: "json" },
      ],
      companyId: "",
      userId: "",
    };
  },

  methods: {
    /* 

    Route GET /logs gibt's schon länger. Mögliche Parameter:

    filter[userId]={userID}
    filter[adminId]={adminID}
    filter[companyId]={companyID}
    filter[tableName]={tableName}
    filter[tableId]={tableID}
    filter[action]={action} e.g. create|update|delete
    filter[level]={level} e.g. info|warning|error|emergency
    filter[type]={type} e.g. internal|auth|email|notification|database|filesystem|lead|api
    filter[data]={searchString} e.g. tobi@gerlach.de|%teilstring%
    limit={limit} Default:20
    offset={offset} Default:0
    sortBy={sortBy} e.g. id|created|action|level|type
    orderBy={orderBy} e.g. ASC|DESC
    Beispiel: GET https://apislim.devel.mitfit.de/v1/logs?filter[type]=email&filter[data]=%@gmx.de%

    Zusätzlich wird "leadFullName" zurückgegeben (ungleich NULL, wenn f_table = "leads").

    Für die Tabelle "apiLog" müssen wir eine separate Route/Actions/Domain anlegen.

    */

    async getData() {
      let filter = "";
      let setup = "";

      if (this.type) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[type]=${this.type}`;
      }

      if (this.logLevel) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[level]=${this.logLevel}`;
      }

      if (this.action) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[action]=${this.action}`;
      }

      if (this.tableName) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[tableName]=${this.tableName}`;
      }

      if (this.companyId) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[companyId]=${this.companyId}`;
      }

      if (this.userId) {
        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[userId]=${this.userId}`;
      }

      if (this.query) {
        // check, if query contains a % sign. if not, wrap it with % signs
        if (!this.query.includes("%")) {
          this.query = `%${this.query}%`;
        }

        if (filter) filter += "&";
        else filter += "?";
        filter += `filter[${this.searchIn}]=${encodeURIComponent(this.query)}`;
      }

      if (this.limit) {
        if (!filter) {
          if (!setup) {
            setup += `?limit=${this.limit}`;
          } else {
            setup += `&limit=${this.limit}`;
          }
        } else {
          setup += `&limit=${this.limit}`;
        }
      }

      if (this.offset) {
        if (!filter) {
          if (!setup) {
            setup += `?offset=${this.offset}`;
          } else {
            setup += `&offset=${this.offset}`;
          }
        } else {
          setup += `&offset=${this.offset}`;
        }
      }

      if (this.sortBy) {
        if (!filter) {
          if (!setup) {
            setup += `?sortBy=${this.sortBy}`;
          } else {
            setup += `&sortBy=${this.sortBy}`;
          }
        } else {
          setup += `&sortBy=${this.sortBy}`;
        }
      }

      if (this.orderBy) {
        if (!filter) {
          if (!setup) {
            setup += `?orderBy=${this.orderBy}`;
          } else {
            setup += `&orderBy=${this.orderBy}`;
          }
        } else {
          setup += `&orderBy=${this.orderBy}`;
        }
      }

      this.preloader = true;
      let response = await this.getRequest(`logs${filter}${setup}`);
      this.preloader = false;

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          { text: "Datum", value: "created" },
          { text: "User ID", value: "users_id" },
          { text: "Admin ID", value: "admins_id" },
          { text: "Firmen ID", value: "companies_id" },
          { text: "Firmenname", value: "companyName" },
          { text: "Benutzername", value: "userName" },
          { text: "Leadname", value: "leadFullName" },
          { text: "Tabelle", value: "f_table" },
          { text: "Action", value: "action" },
          { text: "Level", value: "level" },
          { text: "Typ", value: "type" },
          { text: "Data", value: "data" },
          { text: "JSON", value: "json" },
        ];

        this.data = response.data.data;
      }
    },
  },

  mixins: [apiRequest],
};
</script>
